import React, { useState } from "react";
import "./ModalCreateUser.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getAuthToken } from "../../services/auth";
import { CREATEUSER } from "../../contexts/endpoints";
import { toast } from "react-toastify";
import axios from "axios";

function ModalCreateUser(props) {
  const [username, setUsername] = useState("");
  const [rank, setRank] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const { usersListRank } = props;


  //CreateUser
  const createUsersList = async (event) => {
    let token = getAuthToken();
    let data = new FormData();
  
    data.append('username', username);
    data.append('email', email);
    data.append('rank', rank);
    data.append('password1', password1);
    data.append('password2', password2);
  
    const configCreateUser = {
      method: "post",
      maxBodyLength: Infinity,
      url: CREATEUSER,
      headers: {
        Authorization: `token ${token}`,
      },
      data: data
    };
  
    try {
      const response = await axios.request(configCreateUser);
      if (response.status === 200) {
        props.callback();
        toast.success("Utilizador criado com sucesso");
        props.onHide();
      } else {
        throw new Error("Failed to create user");
      }
    } catch (error) {
      toast.error("Utilizador não criado");
      console.log(error);
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Criar utilizador</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="headBody">
          <Row>
            <Col md={8}>
              <p className="mb-0">Nome Utilizador</p>
              <input
                className="w-100 inputs-forms"
                type="text"
                placeholder="Inserir nome"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Col>
            <Col md={4}>
              <p className="mb-0">Email</p>
              <input
                className="w-100 inputs-forms"
                type="email"
                placeholder="Inserir email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-4">
          <Col md={4}>
              <p className="mb-0">Nível do utilizador</p>
              <select
                style={{ appearance: 'auto'}}
                className="w-100 inputs-forms"
                placeholder="Inserir o nome do utilizador"
                onChange={(e) => setRank(e.target.value)}
              >
                {Object.entries(usersListRank)
                .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
                .map(([name, rankNumber]) => (
                  <option key={rankNumber} value={rankNumber}>
                    {name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4} >
            <p className="mb-0">Password</p>
            <input
                className="w-100 inputs-forms"
                type="text"
                placeholder="Password"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
            </Col>
            <Col md={4}>
            <p className="mb-0">Inserir novamente a password</p>
            <input
                className="w-100 inputs-forms"
                type="text"
                placeholder="Repetir Password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </Col>
          </Row>
          <div className="mt-5 d-flex justify-content-end ">
            <button className="btnCancel" onClick={props.onHide}>Cancelar</button>
            <button className="btnFinish ml-1" onClick={createUsersList}>Criar</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCreateUser;
