import React from "react";
import "./ModalView.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function ModalView(props) {
  // Hide the properties I dont want to show in modal
  const filteredProps = Object.entries(props).filter(
    ([key]) =>
      ![
        "show",
        "onHide",
        "backdrop",
        "Activo",
        "activo",
        "Posto",
        "posto",
        "result",
        "Turno_actual",
        "turno_actual",
        "Data",
        "data",
        "data turno actual",
        "Data turno actual",
      ].includes(key)
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      {filteredProps.length > 0 ? (
          <h4 className="mb-0">{props.result}</h4>
        ) : (
          <h4 className="mb-0">Desligada</h4>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="headBody">
          <Row>
            <Col md={12} className="d-flex justify-content-between flex-column">
              {filteredProps
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by key in alphabetical order
                .map(([key, value], index) => (
                  <React.Fragment key={key}>
                    <div className="d-flex justify-content-between">
                      <p className="mb-0 blue">
                        <strong>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</strong>
                      </p>
                      <p className="mb-0">{value}</p>
                    </div>
                    {index !== filteredProps.length - 1 && (
                      <hr style={{ color: "lightgray" }} />
                    )}
                  </React.Fragment>
                ))}
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalView;
