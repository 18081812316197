import React, { useState, useEffect } from "react";
import "./usersList.scss";
import {  GETALLUSERS, GETRANKS } from "../../contexts/endpoints";
import { getAuthToken } from "../../services/auth";
import axios from "axios";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import ModalCreateUser from "../ModalCreateUser/ModalCreateUser";
import ModalEditUser from "../ModalEditUser/ModalEditUser";
import ModalConfirm from "../ModalConfirm/ModalConfirm";

const ICON_SIZE = 16; // Define a constant for the size of the icons

function UsersList() {
  const [usersList, setUsersList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState("");
  const [usersListRank ,setUsersListRank] = useState("")
  const [selectedUser, setSelectedUser] = useState(null);
  const user = useSelector((state) => state.user.data);


  //GET UsersList
  const getUsersList = async () => {
    let token = getAuthToken();

    const configListUsers = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETALLUSERS,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configListUsers);
      if (response.status === 200) {
        setUsersList(response.data.result);
        //console.log(response.data.result);
      } else {
        throw new Error("Failed to change password");
      }
    } catch (error) {
      //toast.success(`Falha ao mudar a password ${error}`);
      console.log(error);
    }
  };


  //GET UsersRanks
  const getUsersRank = async () => {
    let token = getAuthToken();

    const configListUsersRank = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETRANKS,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configListUsersRank);
      if (response.status === 200) {
        setUsersListRank(response.data);
        //console.log(usersListRank);
      } else {
        throw new Error("Failed to change password");
      }
    } catch (error) {
      //toast.success(`Falha ao mudar a password ${error}`);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getUsersRank(), getUsersList()]);
    };
  
    fetchData();
  }, []);


  
  // Function to map the rank number to its representation
  const mapRank = (rank) => {
    const rankNames = Object.keys(usersListRank);
    for (const name of rankNames) {
      if (usersListRank[name] === rank) {
        return name;
      }
    }
    return "Unknown Rank";
  };

  const openEditModal = (user) => {
    setSelectedUser(user); // Set the selected user for editing
    setModalShowEdit(true); // Open the edit modal
  };

  const openDeleteUser = (user) => {
    setSelectedUserToDelete(user);
    setModalConfirm(true)
  }


  return (
    <>
      <ModalCreateUser
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop="static"
          callback={getUsersList}
          usersListRank={usersListRank}
        />

        <ModalConfirm
        show={modalConfirm}
        onHide={() => setModalConfirm(false)}
        user={selectedUserToDelete} // Pass the selected user
        callback={getUsersList} // Pass the callback function to update the user list
        userForDelete={selectedUserToDelete.username}
        titulo="Eliminar"
        />

        <ModalEditUser
          show={modalShowEdit}
          onHide={() => setModalShowEdit(false)}
          backdrop="static"
          user={selectedUser}
          callback={getUsersList}
          usersListRank={usersListRank}
        />

      <div className="mt-4">
        {(user.rank === 50 || user.rank === 30) && (
          <div className="d-flex justify-content-end ">
            <button className="btnEdit" onClick={() => setModalShow(true)}>Adicionar utilizador</button>
          </div>
        )}
        <table className="mt-4">
          <thead className="colorHead">
            <tr>
              <th>Nome do Utilizador</th>
              <th>Email</th>
              <th>Rank</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usersList.map((user, index) => (
              <tr className="tableTr" key={user.id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{mapRank(user.rank)}</td>
                <td>
                  <button className="btnDell" onClick={() => openDeleteUser(user)}>
                    <AiFillDelete size={ICON_SIZE} />
                  </button>
                  <button
                  className="btnDell"
                  onClick={() => openEditModal(user)} // Pass the user object to openEditModal
                  style={{ marginLeft: "20px" }}
                >
                  <AiTwotoneEdit size={ICON_SIZE} />
                </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UsersList;
