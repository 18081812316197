import Row from "react-bootstrap/esm/Row";
import ImageUploaderHome from "../imageUploaderHome/ImageUploaderHome";
import UploadConfigFile from "../uploaderConfigFile/UploaderConfigFile";
import "./tabs.scss";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import DownloadConfigFile from "../downloadConfigFile/DownloadConfigFile";
import UserInformation from "../userInformation/UserInformation";
import UsersList from "../usersList/UsersList";
import { useSelector } from "react-redux";


function Tabs() {
  const [toggleState, SetToggleState] = useState(2);
  const user = useSelector((state) => state.user.data);
  const toggleTab = (index) => {
    SetToggleState(index);
  };

  return (
    <div className="containerSettings">
      <div className="bloc-tabs">
      {(user.rank === 50 || user.rank === 40) && (
        <div
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          <p className="m-0">Configuração</p>
        </div>
        )}
        <div
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          <p className="m-0">Dados pessoais</p> 
        </div>
        {(user.rank === 50 || user.rank === 30) && (
        <div
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          <p className="m-0">Lista de utilizadores</p>
        </div>
        )}
      </div>
      <div className="content-tabs">
      {(user.rank === 50 || user.rank === 30) && (
        <div
          className={toggleState === 1 ? "content active-content" : "content"}
        >
          <h3>Configuração</h3>
          <Row>
            <Col md={6} sm={12} >
              <UploadConfigFile/> 
              <DownloadConfigFile/>
            </Col>
            <Col md={6} sm={12}>
              <ImageUploaderHome  />
            </Col>
          </Row>
        </div>
      )}
        <div
          className={toggleState === 2 ? "content active-content" : "content"}
        >
          <h3>Dados Pessoais</h3>
          <Row>
            <Col md={12} className="align-items-end d-flex">
              <UserInformation/>
            </Col>
          </Row>
        </div>
        {(user.rank === 50 || user.rank === 30) && (
        <div
          className={toggleState === 3 ? "content active-content" : "content"}
        >
          <h3>Lista de utilizadores</h3>
          <Row>
            <Col>
              <UsersList/>
            </Col>
          </Row>
        </div>
        )}
      </div>
    </div>
  );
}

export default Tabs;
