import { createSlice } from '@reduxjs/toolkit';

export const machinesSlice = createSlice({
  name: 'machines',
  initialState: {
    machinesByGroups: {},
    selectedFactory: null,
    selectedGroup: null,
  },
  reducers: {
    setMachinesByGroups(state, action) {
      state.machinesByGroups = action.payload;
    },
    selectFactoryAndGroup(state, action) {
      state.selectedFactory = action.payload.factory;
      state.selectedGroup = action.payload.group;
    },
  },
});

export const { setMachinesByGroups, selectFactoryAndGroup } = machinesSlice.actions;
export default machinesSlice.reducer;