import React, { useState, useEffect } from "react";
import "./userInformation.scss";
import axios from "axios";
import { EDITUSER } from "../../contexts/endpoints";
import { getAuthToken } from "../../services/auth";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { updateUsername } from "../../store/modules/userSlice";

const UserInformation = () => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  // Set initial values when the component mounts
  useEffect(() => {
    if (user) {
      setUsername(user.name);
      setEmail(user.email);
    }
  }, [user]);

  const handleChangeUser = async () => {
    let token = getAuthToken();
    let data = new FormData();

    data.append("username", username);
    data.append("email", email);

    const configEditUser = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${EDITUSER}${user.id}/`,
      headers: {
        Authorization: `token ${token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(configEditUser);
      if (response.status === 200) {
        toast.success("Utilizador editado com sucesso");
        dispatch(updateUsername({ userId: user.id, newUsername: username }));
      } else {
        throw new Error("Erro ao editar utilizador");
      }
    } catch (error) {
      toast.error("Erro ao editar utilizador");
      console.log(error);
    }
  };

  const handleChangeUserPassword = async () => {
    if (!password1 || !password2) {
      toast.error("Preencha todos os campos na secção da password para conseguir alterar");
      return;
    }

    if (password1 !== password2) {
      toast.error("As passwords não coincidem");
      return;
    }

    let token = getAuthToken();
    let data = new FormData();

    data.append("password1", password1);
    data.append("password2", password2);

    const configEditUserPassword = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${EDITUSER}${user.id}/`,
      headers: {
        Authorization: `token ${token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(configEditUserPassword);
      if (response.status === 200) {
        toast.success("Password editada com sucesso");
      } else {
        throw new Error("Erro ao editar password");
      }
    } catch (error) {
      toast.error("Erro ao editar password");
      console.log(error);
    }
  };

  return (
    <>
    <div className="mt-4 w-100">
      {/* Change Password */}
      <Row className="mb-4">
        <span className="subtitle"><strong>Alterar</strong> e <strong>atualizar </strong>dados do utilizador</span>
      </Row>
      <Row>
        <Col md={4}>
          <p className="mb-0">
            Nome do utilizador:
            <input
              className="inputs-forms"
              type="text"
              placeholder={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </p>
        </Col>
        <Col md={4}>
          <p className="mb-0">
            Email do utilizador:
            <input
              className="inputs-forms"
              type="email"
              placeholder={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </p>
        </Col>
      </Row>
      <button className="btnEdit ml-1 mt-4" onClick={handleChangeUser}>
        Editar
      </button>
    </div>
    <div className="mt-4 w-100">
    <Row>
        <Col md={4}>
          <p className="mb-0">
            Colocar nova Password:
            <input
              className="inputs-forms"
              type="text"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
          </p>
        </Col>
        <Col md={4}>
          <p className="mb-0">
            Repetir nova password:
            <input
              className="inputs-forms"
              type="text"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </p>
        </Col>
      </Row>
      <button className="btnEdit ml-1 mt-4" onClick={handleChangeUserPassword}>
        Editar Password
      </button>
    </div>
    </>
  );
};

export default UserInformation;
