import './navbar.scss';
import logo from '../../assets/img/logo.svg';
import { Link } from 'react-router-dom';
import UserComponent from '../userComponent/UserComponent';

function NavBar() {

  //variavel pra ver se o utilizador está logado
  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser'));


  return (
    <>
      {loggedInUser && (
        <header className="d-flex justify-content-space-between align-items-center">
          <div>
            <Link to="/pages/mainFactory">
              <img className="logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div>
            <UserComponent />
          </div>
        </header>
      )}
    </>
  );
}

export default NavBar;