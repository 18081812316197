import './addbutton.scss';
import { IoMdAdd } from "react-icons/io";

function AddButton() {

    const ICON_SIZE = 22;

    return ( 
        <div className="bodyCircle" >
            <IoMdAdd className='iconAdd' size={ICON_SIZE} />
        </div>
     );
}

export default AddButton;