import React, { useEffect, useState } from "react";
import "./table.scss";
import ModalView from "../ModalView/ModalView";

function Table({ data, infomachine }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedResult, setSelectedResult] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [selectedMachine, setSelectedMachine] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [operationInfo, setOperationInfo] = useState("");
  const [modalProps, setModalProps] = useState({});

  const handleMachineClick = (machine, result) => {
    if (machine !== "Desligada") {
      setSelectedMachine(machine);
      setModalShow(true);
      setOperationInfo(machine);

      const filteredMachineData = Object.fromEntries(
        Object.entries(machine).filter(([key, value]) => value !== "")
      );

      const formattedMachineData = Object.fromEntries(
        Object.entries(filteredMachineData).map(([key, value]) => [
          key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
          value,
        ])
      );

      setModalProps({ result, ...formattedMachineData });
    }
  };

  //calculate the color depending on the mainslabel you choose and with the values ​​of the thresholds of each machine
  const getColor = (machine, name_posto) => {
    const mainlabel = data.groupDetails.main_label;
    const postoLabel = data.groupDetails.postolabel;
    const efficiency = parseInt(machine?.[mainlabel]) || 0;
    // console.log("mainlabel", mainlabel);
    // console.log("postoLabel", postoLabel);
    //console.log("efficiency", efficiency);

    // eslint-disable-next-line no-unused-vars
    let name = machine?.[postoLabel];
    let postos = data.postos;
    //console.log("machine", machine);
    //console.log("postos", postos);

    let findMachine = null;

    for (const key in postos) {
      if (key === name_posto) {
        findMachine = postos[key];
        //console.log("name_posto", name_posto);
        //console.log("variavelencontrada", findMachine);
        break;
      }
    }

    if (findMachine) {
      const thresholds = findMachine.thresholds || [];
      const [firstThreshold, secondThreshold, thirdThreshold, fourthThreshold] =
        thresholds;

      if (efficiency > firstThreshold && efficiency < secondThreshold) {
        return "#FE2020"; // Color for efficiency between 0 and 89 (exclusive)
      } else if (efficiency >= secondThreshold && efficiency < thirdThreshold) {
        return "#FFD800"; // Color for efficiency between 90 and 94 (inclusive)
      } else if (
        efficiency >= thirdThreshold &&
        efficiency <= fourthThreshold
      ) {
        return "green"; // Color for efficiency between 95 and 104 (inclusive)
      } else if (efficiency > fourthThreshold) {
        return "#005d08"; // Color for efficiency greater than 105
      } else {
        return "#376eb5"; // Default color for any other case
      }
    } else {
      // Handle the case where no matching machine is found
      return "Black"; // Default color for any other case
    }
  };

  useEffect(() => {
    if (Object.keys(data).length && Object.keys(infomachine).length) {
      let arrayTable = Array(8)
        .fill()
        .map(() => Array(18).fill());
      let finalTable = [];
      let colByPass = 0;
      // eslint-disable-next-line no-unused-vars
      let machineName = "";

      arrayTable.forEach((row, idx) => {
        let finalTableRow = [];
        row.forEach((col, idx2) => {
          if (colByPass === 0) {
            let machine = <td style={{ borderStyle: "none" }}></td>;
            let result = "";
            let found = false;
            Object.entries(data.postos).forEach((entry) => {
              const {
                card: { columnposition, rowposition, columnquantity },
              } = entry[1];
              if (columnposition === idx2 && rowposition === idx) {
                if (!found) {
                  result = entry[0];
                  found = true;
                  arrayTable[idx][idx2] = result;
                  for (let i = 0; i < columnquantity; i++) {
                    const colIdx = idx2 + i;
                    arrayTable[idx][colIdx] = result;
                  }
                  colByPass = columnquantity - 1;

                  let matchingMachine = null; // Initialize matchingMachine

                  if (infomachine && infomachine.data) {
                    matchingMachine = infomachine.data.find((machine) => {
                      return Object.prototype.hasOwnProperty.call(
                        machine,
                        result
                      );
                    });
                  }
                  //console.log("matchingMachine", matchingMachine);

                  // const rowStyle = {
                  //   background: getColor(matchingMachine[result], result),
                  // };

                  const fallbackValue1 =
                    matchingMachine?.[result].SetupRestante;

                  const cellContent =
                    matchingMachine &&
                    matchingMachine[result] &&
                    matchingMachine[result][data.groupDetails.cardlabel]
                      ? matchingMachine[result][data.groupDetails.cardlabel]
                      : fallbackValue1 || "Desligada";

                  const rowStyle = {
                    background:
                      cellContent === "Desligada"
                        ? "black"
                        : getColor(matchingMachine[result], result),
                   };

                   if (rowStyle.background === "#FFD800") {
                    rowStyle.color = "black";
                  }

                  console.log(
                    `Cell Content: ${cellContent}, Fallback Value 1: ${fallbackValue1}, Background: ${rowStyle.background}, Text Color: ${rowStyle.color}`
                  );

                  machine = (
                    <td
                      key={`${idx}-${idx2}`}
                      onClick={() =>
                        cellContent !== "Desligada" &&
                        matchingMachine &&
                        handleMachineClick(matchingMachine[result], result)
                      }
                      disabled={cellContent === "Desligada"}
                      className="tabletd"
                      colSpan={`${columnquantity}`}
                      style={{
                        ...rowStyle,
                        // pointerEvents:
                        //   cellContent === "Desligada" ? "none" : "auto",
                      }}
                    >
                      <h5 className="m-0 titlecell">{result}</h5>
                      <p className="m-0 cellOther">{cellContent}</p>
                    </td>
                  );
                }
              }
            });
            finalTableRow.push(machine);
          } else {
            colByPass--;
          }
        });
        finalTable.push(<tr key={idx}>{finalTableRow}</tr>);
      });

      setRows(finalTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, infomachine]);

  return (
    <>
      <ModalView
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        result={selectedResult}
        {...modalProps}
      />
      <table className="table">
        <tbody>{rows}</tbody>
      </table>
    </>
  );
}

export default Table;
