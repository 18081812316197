import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
  },
  reducers: {
    login: (state, action) => {
      state.data = action.payload.data;
    },
    logout: (state) => {
      state.data = null;
    },
    updateUsername: (state, action) => {
      const { userId, newUsername } = action.payload;
      if (state.data && state.data.id === userId) {
        state.data.name = newUsername;
      }
    },
  },
});

export const { login, logout, updateUsername } = userSlice.actions;
export default userSlice.reducer;