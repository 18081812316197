import React, { useState, useEffect } from "react";
import "./sidebar.scss";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiFactory } from "react-icons/gi";
import { MdChromeReaderMode } from "react-icons/md";
import axios from "axios";
import { getAuthToken } from "../../services/auth";
import { GETALL, GETFACTORIES } from "../../contexts/endpoints";
import Dropdown from "react-multilevel-dropdown";
import { useDispatch } from "react-redux";
import { selectFactoryAndGroup } from "../../store/modules/machinesSlice";
import { useSelector } from "react-redux";

const ICON_SIZE = 22; // Define a constant for the size of the icons

function getFactoryGroups(hashmap) {
  // Define a function to get the factory groups from a hashmap
  if (!hashmap || !hashmap.factories) {
    // Check if the hashmap and factories exist
    return {}; // If not, return an empty object
  }

  const factories = hashmap.factories; // Get the factories object from the hashmap
  const factoryGroups = {}; // Initialize an empty object to store the factory groups

  for (const factory in factories) {
    // Iterate through each factory
    const groups = factories[factory]?.groups; // Get the groups object for the current factory, if it exists

    if (groups) {
      // Check if groups exist
      const groupList = []; // Initialize an empty array to store the group names

      for (const group in groups) {
        // Iterate through each group for the current factory
        groupList.push(group); // Add the group name to the groupList array
      }

      factoryGroups[factory] = groupList; // Add the groupList array to the factoryGroups object
    } else {
      factoryGroups[factory] = []; // If groups do not exist, add an empty array to the factoryGroups object
    }
  }

  return factoryGroups; // Return the factory groups
}

function SideBar(props) {
  const [factories, setFactories] = useState([]);
  const [factoriesGroups, setFactoriesGroups] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    // This useEffect hook is used to fetch the list of factories from the API when the component mounts

    // Define an HTTP headers object
    let myHeaders = new Headers();

    // Get the authentication token from the auth service
    let token = getAuthToken();

    // Log the token to the console
    //console.log("token", token);

    // Add the token to the headers object
    myHeaders.append("Authorization", `token ${token}`);

    // Define a configuration object for the HTTP request
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETFACTORIES,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    // Define an asynchronous function to fetch the list of factories
    const fetchFactories = async () => {
      try {
        // Make the HTTP request using Axios
        const response = await axios.request(config);

        // Update the state with the list of factories
        setFactories(response.data);
      } catch (error) {
        // Log any errors to the console
        console.log(error);
      }
    };

    // Call the fetchFactories function when the component mounts (i.e., when the [] dependency array is empty)
    fetchFactories();
  }, []);

  useEffect(() => {
    // Fetch the list of factories from the API when the component mounts
    let myHeaders = new Headers(); // Define an HTTP headers object
    let token = getAuthToken(); // Get the authentication token from the auth service
    myHeaders.append("Authorization", `token ${token}`); // Add the token to the headers object
    let config = {
      // Define a configuration object for the HTTP request
      method: "get",
      maxBodyLength: Infinity,
      url: GETALL, // The API endpoint to fetch the factories from
      headers: {
        Authorization: `token ${token}`, // Add the token to the headers in the config object
      },
    };

    const fetchFactoriesGroups = async () => {
      try {
        const response = await axios.request(config); // Make the HTTP request using Axios
        let groups = getFactoryGroups(response.data); // Process the response data to group factories
        //console.log(groups); // Log the groups to the console
        setFactoriesGroups(groups); // Update the state with the groups of factories
        //console.log(factoriesGroups)
      } catch (error) {
        console.log(error); // Log any errors to the console
      }
    };

    fetchFactoriesGroups(); // Call the fetchFactoriesGroups function when the component mounts
  }, []); // The empty array passed as the second argument to useEffect means that this effect will only run once, when the component mounts.

  // Sidebar component
  return (
    <div className="bgSideBar">
      {/* Home icon */}
      <Link to="/pages/mainFactory" className="btnSideBar mb-3">
        <FaHome className="changeColor" size={ICON_SIZE} />
      </Link>
      <Dropdown
        style={{
          background: "none",
          padding: "0",
          justifyContent: "center",
          marginBottom: "16px",
        }}
        title={
          <div style={{ justifyContent: "center" }}>
            <GiFactory className="changeColor" size={ICON_SIZE} />
          </div>
        }
        position="right"
      >
        {factories.map((factory, index) => (
          <Dropdown.Item key={index}>
            <Link
              className="LinkDropDown"
              onClick={() =>
                dispatch(selectFactoryAndGroup({ factory: factory }))
              }
              to={`/${factory}`}
            >
              {factory}
            </Link>
            {factoriesGroups &&
              factoriesGroups[factory] &&
              factoriesGroups[factory].length > 0 && (
                <Dropdown.Submenu position="right">
                  {factoriesGroups[factory].map((group, index) => (
                    <Dropdown.Item key={index} style={{ whiteSpace: "normal" }}>
                      <Link
                        className="LinkDropDown"
                        onClick={() =>
                          dispatch(
                            selectFactoryAndGroup({
                              factory: factory,
                              group: group,
                            })
                          )
                        }
                        to={`/${factory}/${group}`}
                      >
                        {group}
                      </Link>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu>
              )}
          </Dropdown.Item>
        ))}
      </Dropdown>
      {(user.rank === 50 || user.rank === 30) && (
        <Link
          to="/pages/documentation/"
          className="btnSideBar mb-3"
          title="Documentação"
        >
          <MdChromeReaderMode className="changeColor" size={ICON_SIZE} />
        </Link>
      )}
    </div>
  );
}

export default SideBar;
