import React from 'react';
import './createFile.scss';
import MouseTracker from '../../components/mouseTracker/MouseTracker';
import ImageUploader from '../../components/imageUploader/ImpageUploader';
import withAuth from '../../services/withAuth';

function createFile() {


    return ( 
        

        <div style={{width: '100%'}}>
            <ImageUploader/>
            <MouseTracker/>
        </div>
     );
}

export default withAuth(createFile);