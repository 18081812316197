import "./ModalConfirm.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getAuthToken } from "../../services/auth";
import { DELETEUSER } from "../../contexts/endpoints";
import { toast } from "react-toastify";
import axios from "axios";

function ModalConfirm(props) {
  

  //DELETE userFromList
  const deleteUsersList = async (userId) => { 
    let token = getAuthToken();

    const configDeleteUser = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${DELETEUSER}${userId}/`,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configDeleteUser);
      if (response.status === 200) {
        toast.success("Utilizador eliminado com sucesso");
        props.onHide();
        props.callback();
      } else {
        throw new Error("Failed to delete user");
      }
    } catch (error) {
      toast.error("Utilizador não eliminado");
      console.log(error);
    }
  };


  return (
    <Modal
  {...props}
  size="md"
  aria-labelledby="contained-modal-title-vcenter"
  centered
>
  <Modal.Header closeButton>
    <h4 className="mb-0">{props.titulo}</h4>
  </Modal.Header>
  <Modal.Body>
    <div className="headBody">
      <Row>
        <Col md={12}>
          <p className="mb-0 subtitle">
            Tem a certeza que pretende eliminar o utilizador {" "}
            <strong style={{color: 'black'}}>{props.userForDelete}</strong>
          </p>
        </Col>
      </Row>
      <div className="mt-5 d-flex justify-content-end ">
        <button className="btnCancel" onClick={props.onHide}>
          Cancelar
        </button>
        <button
          className="btnDeleteUser ml-1"
          onClick={() => deleteUsersList(props.user.id)}
        >
          Eliminar
        </button>
      </div>
    </div>
  </Modal.Body>
</Modal>
  );
}

export default ModalConfirm;
