import React, { useState } from "react";


function ImageUploader() {
  
  // Declare state variables for the selected image file and its URL
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  // Function to handle when a file is selected
  function handleFileSelect(event) {
    // Get the selected file
    const file = event.target.files[0];

    // Check if a file was selected
    if (file) {
      // Set the selected file state
      setSelectedFile(file);

      // Use FileReader API to read the selected file as a URL
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Set the image URL state to the file's URL
      reader.onload = function () {
        localStorage.setItem("image", reader.result);
        setImageUrl(reader.result);
      };
    }
  }

  // CSS styles for the div that displays the uploaded image
  const divStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "contain",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "60px",
    height: "100vh",
    width: "100%",
  };

  return (
    <div>
      <input style={{zIndex: '1', position: 'relative', marginLeft: "100px", marginTop: "50px", fontSize: '12px'}} type="file" onChange={handleFileSelect} />
      <div style={divStyle}></div>
    </div>
  );
}

export default ImageUploader;