import "./usercomponent.scss";
import userImg from "../../assets/img/default_avatar.jpg";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, removeLoggedInUser } from "../../services/auth";
import { useSelector } from "react-redux";


const ICON_SIZE = 10;


function UserComponent() {
  const user = useSelector((state) => state.user.data);

  //MENU DROPDOWN
  const [openUser, setOpenUser] = useState(false);
  const menuDrop = useRef();
  const imgDrop = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuDrop.current &&
        !menuDrop.current.contains(event.target) &&
        imgDrop.current !== event.target
      ) {
        setOpenUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuDrop, imgDrop]);
  //END MENU DROPDOWN

  //trazer nome do user logado
  const loggedInUser = getLoggedInUser();
  //console.log(loggedInUser)
  const navigate = useNavigate();
  const handleLogout = () => {
    removeLoggedInUser();
    navigate("/");
  };

  useEffect(() => {
    //console.log("user", loggedInUser);
  }, [loggedInUser]);

  return (
    <>
      {loggedInUser && (
        <div className="relative" onClick={() => setOpenUser(!openUser)}>
          <div className="d-flex align-items-center cursorPointer">
            <img ref={imgDrop} src={userImg} alt="user" className="usrClass" />
            <small className="ml-1"> {user.name}</small>
            <AiOutlineCaretDown className="ml" size={ICON_SIZE} />
          </div>
          {openUser && (
            <div ref={menuDrop} className="dropInfo absolute">
              <ul className="d-flex column p-0">
                <Link
                  to="/pages/settings/Settings"
                  className="btnDropDown justify-content-space-around align-items-center d-flex p-16"
                >
                  Definições
                </Link>
                <button onClick={handleLogout} className="btnDropDown p-16 justify-content-space-around align-items-center d-flex bt-1">
                <RiLogoutBoxLine /> Logout
                </button>
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserComponent;
