import React, { useState, useEffect } from "react";
import "./ModalEditUser.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getAuthToken } from "../../services/auth";
import { EDITUSER } from "../../contexts/endpoints";
import { toast } from "react-toastify";
import axios from "axios";

function ModalEditUser(props) {
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [rank, setRank] = useState("");
  const [email, setEmail] = useState("");
  const { usersListRank } = props;

  useEffect(() => {
    if (props.user) {
      const { id, username, rank, email } = props.user;
      setUserId(id);
      setUsername(username);
      setRank(rank);
      setEmail(email);
    }
  }, [props.user]);

  const editUser = async () => {
    let token = getAuthToken();
    let data = new FormData();

    data.append("username", username);
    data.append("email", email);
    data.append("rank", rank);

    const configEditUser = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${EDITUSER}${userId}/`,
      headers: {
        Authorization: `token ${token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(configEditUser);
      if (response.status === 200) {
        props.callback();
        toast.success("Utilizador editado com sucesso");
        props.onHide();
      } else {
        throw new Error("Erro ao editar utilizador");
      }
    } catch (error) {
      toast.error("Erro ao editar utilizador");
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Editar Utilizador</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="headBody">
          <Row>
            <Col md={4}>
              <p className="mb-0">Nome Utilizador</p>
              <input
                className="w-100 inputs-forms"
                type="text"
                placeholder="Inserir nome"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Col>
            <Col md={4}>
              <p className="mb-0">Email</p>
              <input
                className="w-100 inputs-forms"
                type="email"
                placeholder="Inserir email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col md={4}>
              <p className="mb-0">Nível do utilizador</p>
              <select
                style={{ appearance: "auto" }}
                className="w-100 inputs-forms"
                placeholder="Inserir o nome do utilizador"
                value={rank}
                onChange={(e) => setRank(e.target.value)}
              >
                {Object.entries(usersListRank)
                  .sort(([nameA], [nameB]) => nameA.localeCompare(nameB)) // Sort by name in alphabetical order
                  .map(([name, rankNumber]) => (
                    <option key={rankNumber} value={rankNumber}>
                      {name}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
          <div className="mt-5 d-flex justify-content-end">
            <button className="btnCancel" onClick={props.onHide}>
              Cancelar
            </button>
            <button className="btnFinish ml-1" onClick={editUser}>
              Editar
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditUser;
