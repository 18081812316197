import Tabs from "../../components/tabsSettings/Tabs";
import withAuth from "../../services/withAuth";

function Settings() {
    return ( 
        <div className="ml-60">
            <div>
                <Tabs />
            </div>
        </div>
     );
}

export default withAuth(Settings);