import React from "react";
import { useState } from "react";
import './imageUploaderHome.scss'
import axios from 'axios'
import { GETFACTORIES, POSTLAYOUT } from "../../contexts/endpoints";
import { getAuthToken } from "../../services/auth";
import { toast } from 'react-toastify';

function ImageUploaderHome(props) {

  const [selectedImg, setSelectedImg] = useState(null);

  const handleSubmitImage = async (event) => {
    event.preventDefault();

    let token = getAuthToken();

    const formData = new FormData();
    formData.append('file', selectedImg);

    const selectedImgName = selectedImg.name.split('.')[0]; // Extract the desired part of the filename

    const checkImageExistsConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: GETFACTORIES,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    //This checks if the name of the image matches any factory names in the configuration file
    const checkImageExists = async () => {
      try {
        const response = await axios.request(checkImageExistsConfig);
        const imageExists = response.data.includes(selectedImgName);
    
        if (imageExists) {
          // Image exists, proceed with the upload
          await uploadImage();
        } else {
          toast.error(`A fábrica com o nome ${selectedImgName} não existe `); // show error notification
        }
      } catch (error) {
        console.error(error);
        toast.error(`Error checking image existence: ${error}`); // show error notification
      }
    };

    const uploadImage = async () => {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${POSTLAYOUT}${selectedImgName}/`,
        headers: {
          Authorization: `token ${token}`,
          'Content-Type': 'image/png',
        },
        data: formData,
      };

      try {
        const response = await axios.request(config);
        console.log(response.data);

        if (response.status === 200) {
          toast.success(`A imagem ${selectedImg.name} para o layout foi atualizada com sucesso!`); // show success notification
          setSelectedImg(null)
        } else {
          toast.error(`Erro ao carregar a imagem! ${response.detail}`); // show error notification
        }
      } catch (error) {
        console.error(error);
        toast.error(`Erro ao carregar a imagem! ${error}`); // show error notification
      }
    };

    checkImageExists();
  };

  const handleImgSelect = (event) => {
    setSelectedImg(event.target.files[0]);
  };


  return ( 
    <div>
      <form className='insertDoc' onSubmit={handleSubmitImage}>
        <span className="subtitle"><strong>Carregar</strong> o <strong>novo Layout</strong> para as fábricas</span>
        <small>O nome da imagem tem de ser exatamente igual ao que colocamos no ficheiro de configuração.</small>
        <div className='d-flex'>
            <label htmlFor="file1" id='file-label-Img'>
            Carregar Ficheiro
            </label>
            {selectedImg && <button className='btnUpload' type="submit">Upload</button>}
            
        </div>
      {selectedImg && <small className='sm-file'>Ficheiro selecionado: <strong>{selectedImg.name}</strong></small>}
        <input hidden className='btnAddFile' type="file" id="file1" onChange={handleImgSelect} />
      </form>
    </div>
   );
}

export default ImageUploaderHome;