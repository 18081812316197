import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./GlobalStyleSheets.scss";
import Login from "./pages/login/Login";
import HomePage from "./pages/homePage/HomePage";
import SideBar from "./components/sideBar/SideBar";
import NavBar from "./components/navBar/NavBar";
import CreateFile from "./pages/createFile/CreateFile";
import Settings from "./pages/settings/Settings";
import Documentation from "./pages/documentation/Documents"
import FactoryPage from "./pages/factoryPage/FactoryPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store/store";
import MainFactory from "./pages/mainFactory/MainFactory";

function App() {
  const location = useLocation();
  // Define an array of routes that should include the sidebar
  const routesWithSidebar = [
    "/pages/homePage/HomePage",
    "/pages/settings/Settings",
    "/pages/createFile/CreateFile",
    "/pages/documentation/",
    "/:factory/:group",
    "/:factory/:group/*", // Add this line to match any sub-routes of the /factory/group route
  ];

  // Check if the current route is in the array of routes with sidebar
  const shouldRenderSidebar = routesWithSidebar.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer style={{ fontSize: "14px" }} />
        {shouldRenderSidebar && <SideBar />}
        {shouldRenderSidebar && <NavBar />}
        <Routes>
          <Route path="/pages/mainFactory" element={<MainFactory />} />
          <Route path="/:factory/" element={<HomePage />} />
          <Route path="/pages/settings/Settings" element={<Settings />} />
          <Route path="/pages/documentation/" element={<Documentation />} />
          <Route path="/pages/createFile/CreateFile" element={<CreateFile />} />
          <Route path="/:factory/:group" element={<FactoryPage />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </Provider>
    </div>
  );
}

function RouterApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default RouterApp;
