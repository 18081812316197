/* eslint-disable react-hooks/exhaustive-deps */
import "./homepage.scss";
import React from "react";
import AddButton from "../../components/addButton/AddButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import withAuth from "../../services/withAuth";
import ModalView from "../../components/ModalView/ModalView";
import { getAuthToken } from "../../services/auth";
import {
  GETLAYOUT,
  APIHOST,
  GETMACHINESBYFACTORYHOME,
  GETALL,
  GETMACHINESBYFACTORYINFO,
} from "../../contexts/endpoints";
import { useSelector } from "react-redux";
import SideBar from "../../components/sideBar/SideBar";
import NavBar from "../../components/navBar/NavBar";

function MainFactory() {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [configData, setConfigData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [mainFactory, setMainFactory] = useState("");
  const [dataInfoMachine, setDataInfoMachine] = useState([]);
  const [modalProps, setModalProps] = useState({});
  const user = useSelector((state) => state.user.data);

  // this gives me the factory which is selected in the config file
  const fetchMainFactory = async () => {
    const token = getAuthToken();
    const configMain = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETALL,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configMain);
      const mainFactoryValue = response.data.mainfactory;
      setMainFactory(mainFactoryValue);
      // console.log(mainFactoryValue);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMainFactory();
  }, []);

  // this gives me the factory image
  const fetchLayoutImage = async () => {
    const token = getAuthToken();
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETLAYOUT,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.request(config);
      const randomString = Math.random().toString(36).substring(7);
      const imageUrl = `${APIHOST}/api/media/layouts/${mainFactory}.png?auth_token=${token}&v=${randomString}`;
      setBackgroundImage(imageUrl);
    } catch (error) {
      console.log(error);
    }
  };

  //this gives me the list of machines corresponding to the selected factory
  const getMachinesList = async () => {
    const token = getAuthToken();

    const configListMachines = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${GETMACHINESBYFACTORYHOME}${mainFactory}/`,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configListMachines);
      if (response.status === 200) {
        setConfigData(response.data);
        //console.log(response.data);
      } else {
        throw new Error("Failed to retrieve machine list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // this gives me the information I need for each machine
  const getMachinesInfo = async () => {
    const token = getAuthToken();

    const configListMachinesInfo = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${GETMACHINESBYFACTORYINFO}${mainFactory}/`,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    try {
      const response = await axios.request(configListMachinesInfo);
      if (response.status === 200) {
        setDataInfoMachine(response.data);
        //console.log("AQUI", response.data)
      } else {
        throw new Error("Failed to retrieve machine list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMachineClick = (machineId) => {
    setSelectedMachineId(machineId);
    setModalShow(true);

    let foundMachine = null;

    // Iterate through each group in the dataInfoMachine.data array
    for (const groupData of dataInfoMachine.data) {
      for (const groupKey in groupData) {
        const machines = groupData[groupKey];
        if (machines[machineId]) {
          foundMachine = machines[machineId];
          break;
        }
      }
      if (foundMachine) {
        break;
      }
    }

    if (foundMachine) {
      const filteredMachineData = Object.entries(foundMachine)
        .filter(([key, value]) => value !== "")
        .reduce((obj, [key, value]) => {
          const lowercaseKey = key.toLowerCase();
          obj[lowercaseKey] = value;
          return obj;
        }, {});

      if (Object.keys(filteredMachineData).length > 0) {
        setModalProps(filteredMachineData);
      } else {
        setModalShow(false);
      }
    } else {
      setModalShow(false);
    }
  };

  const getColor = (machineId, group) => {
    let mainlabel = null;
    let postoLabelUse = null;
    let thresholds = null;
    //console.log("group", group);

    for (const key in configData) {
      if (key === group) {
        const groupObject = configData[key];
        mainlabel = groupObject.main_label;
        postoLabelUse = groupObject.postolabel;
        thresholds = groupObject.postos[machineId].thresholds;
        //console.log("groupObject", groupObject);
        break;
      }
    }

    let foundMachine = null;

    const findMachine = (val) => {
      if (val[postoLabelUse] === machineId) {
        foundMachine = JSON.parse(JSON.stringify(val));
        return true;
      }
    };

    const groupData = dataInfoMachine.data[0][group];
    const machines = JSON.parse(JSON.stringify(groupData));
    Object.entries(machines).some(([key, val]) => findMachine(val));
    //console.log("machines", machines);

    //console.log("FOUND", foundMachine);

    if (!foundMachine) {
      return "black";
    }

    const efficiency = parseInt(foundMachine?.[mainlabel]) || 0;
    //console.log("efficiency", efficiency);

    let postos = {};

    for (const groupKey in configData) {
      if (configData.hasOwnProperty(groupKey)) {
        const groupObject = configData[groupKey];
        postos = { ...postos, ...groupObject.postos };
      }
    }
    //console.log("postos", postos);

    if (foundMachine) {
      const [firstThreshold, secondThreshold, thirdThreshold, fourthThreshold] =
        thresholds;

      // console.log(
      //   "firstThreshold",
      //   firstThreshold,
      //   "secondThreshold",
      //   secondThreshold,
      //   "thirdThreshold",
      //   thirdThreshold,
      //   "fourthThreshold",
      //   fourthThreshold
      // );

      if (efficiency > firstThreshold && efficiency < secondThreshold) {
        return "#FE2020"; // Color for efficiency between 0 and 89 (exclusive)
      } else if (efficiency >= secondThreshold && efficiency < thirdThreshold) {
        return "#FFD800"; // Color for efficiency between 90 and 94 (inclusive)
      } else if (
        efficiency >= thirdThreshold &&
        efficiency <= fourthThreshold
      ) {
        return "green"; // Color for efficiency between 95 and 104 (inclusive)
      } else if (efficiency > fourthThreshold) {
        return "#005d08"; // Color for efficiency greater than 105
      } else {
        return "#376eb5"; // Default color for any other case
      }
    } else {
      // Handle the case where no matching machine is found
      return "black"; // Default color for any other case
    }
  };

  useEffect(() => {
    if (mainFactory) {
      getMachinesList();
      fetchLayoutImage();
      getMachinesInfo();

      const interval = setInterval(() => {
        getMachinesInfo();
      }, 6 * 60 * 1000); // 6 minutes in milliseconds

      return () => {
        clearInterval(interval);
      };
    }
  }, [mainFactory]);

  return (
    <>
      <SideBar />
      <NavBar />
      <ModalView
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        result={selectedMachineId}
        {...modalProps}
      />
      <div
        className="bgHome"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {Object.entries(configData).map(([groupKey, group]) =>
          Object.entries(group.postos).map(([machineId, machineData]) => {
            const { top, left, width, height } = machineData.coordinates;

            const divStyle = {
              position: "absolute",
              cursor: "pointer",
              left: `${left}%`,
              top: `${top + 10.2}%`,
              width: `${width}%`,
              height: `${height}%`,
              background: Object.keys(dataInfoMachine).length
                ? getColor(machineId, groupKey)
                : "",
            };

            return (
              <div
                key={machineId}
                style={divStyle}
                onClick={() => handleMachineClick(machineId)}
              ></div>
            );
          })
        )}
        {(user.rank === 50 || user.rank === 30) && (
          <Link to="/pages/createFile/CreateFile">
            <AddButton />
          </Link>
        )}
        {(user.rank === 50 || user.rank === 30) && (
          <Link to="/pages/createFile/CreateFile">
            <AddButton />
          </Link>
        )}
      </div>
    </>
  );
}

export default withAuth(MainFactory);
