import "./login.scss";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAuthToken, saveLoggedInUser } from "../../services/auth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { GETALLUSERS, POSTLOGIN } from "../../contexts/endpoints";
import { useDispatch } from "react-redux";
import { login } from "../../store/modules/userSlice";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();

    let data = JSON.stringify({
      username: username,
      password: password,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: POSTLOGIN,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        if (res.status === 200) {
          let rjson = res.data;

          if (!rjson.hasOwnProperty("token")) {
            return console.log("Body is missing token key");
          }
          let token = rjson.token;
          saveAuthToken(token);

          let userConfig = {
            method: "GET",
            maxBodyLength: Infinity,
            url: `${GETALLUSERS}?username=${username}`,
            headers: {
              Authorization: `token ${token}`,
            },
          };

          axios
            .request(userConfig)
            .then((res) => {
              if (res.status === 200) {
                let rjson = res.data;

                if (!rjson.hasOwnProperty("result")) {
                  return console.log("Body is missing result key");
                }
                let userId = rjson.result[0].id;
                let userRank = rjson.result[0].rank;
                let userEmail = rjson.result[0].email;
                saveLoggedInUser(username, userId);
                dispatch(
                  login({
                    data: {
                      name: username,
                      id: userId,
                      rank: userRank,
                      email: userEmail,
                    },
                  })
                );
                navigate("/pages/mainFactory");
              } else {
                setError("Login falhado. tente novamente");
              }
            })
            .catch((err) => {
              console.log(err);
              setError("Login falhado. tente novamente");
            });
        } else {
          setError("Login falhado. tente novamente");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Login falhado. tente novamente");
      });
  }

  return (
    <div className="d-flex flex-column">
      <Row className="imgbg d-flex flex-column justify-content-center align-items-center h-100vh m-0">
        <Col md={4} sm={12}>
          <form className="bodyform" onSubmit={handleSubmit}>
            <Col md={12} className="mb-1">
              <p className="txt">FactoryEye</p>
            </Col>
            <Col md={12} className="txt-title">
              <h2>Experiência do chão de fábrica</h2>
            </Col>
            <div className="d-flex column mb-1">
              <input
                className="inputs-form"
                type="text"
                autoComplete="Username"
                placeholder="Coloque o Utilizador"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="d-flex column mb-1">
              <input
                className="inputs-form"
                type="password"
                placeholder="Coloque a password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="w-100 btnLogin">Login</button>
            {error && (
              <div className="error">
                <small style={{ color: "white" }}>{error}</small>
              </div>
            )}
          </form>
        </Col>
        <Col
          style={{
            position: "absolute",
            bottom: "12px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "white", fontSize: "10px" }}>Version 1.0.9</span>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
