import { useEffect, useState } from "react";
import { APIHOST } from "../../contexts/endpoints";
import { getAuthToken } from "../../services/auth";
import withAuth from "../../services/withAuth";
import React from "react";

function Documentation() {
  const token = getAuthToken();

  const [iframeHeight, setIframeHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = 69; // adjust this to match your header height
      const availableHeight = window.innerHeight - headerHeight;
      setIframeHeight(availableHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styleIframe = {
    position: "relative",
  };

  return (
    <div className="ml-60" style={styleIframe}>
      <div style={{ height: iframeHeight, width: "100%" }}>
        <iframe
          title="MkDocs"
          src={`${APIHOST}/mkdocs/UserDocumentation/?auth_token=${token}`}
          style={{ height: iframeHeight, width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
}

export default withAuth(Documentation);
