import React, { useState } from 'react';

function MouseTracker() {
  const [isResizing, setIsResizing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleMouseDown = (event) => {
    setIsResizing(true);
    setStartPos({ x: event.clientX, y: event.clientY });
    setSize({ width: 0, height: 0 });
  };

  const handleMouseMove = (event) => {
    if (!isResizing) return;

    const container = document.getElementById('container');
    const containerRect = container.getBoundingClientRect();

    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    const width = ((event.clientX - startPos.x) / containerWidth) * 100;
    const height = ((event.clientY - startPos.y) / containerHeight) * 100;

    setSize({ width, height });
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const { width, height } = size;

  const divStyle = {
    position: 'absolute',
    top: `${((startPos.y + window.pageYOffset - 135) / window.innerHeight) * 100 }%`,
    left: `${((startPos.x + window.pageXOffset) / window.innerWidth) * 100}%`,
    width: `${width.toFixed(2)}%`,
    height: `${height.toFixed(2)}%`,
    border: '1px solid black',
  };

  return (
    <div
      id="container"
      style={{ position: 'relative', height: '100vh' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div style={divStyle}></div>
      <div style={{ width: '30%', marginLeft: '100px', marginTop: '20px' }}>
        <p>
          <small>
            Selecione a imagem e clique para criar a área da máquina no layout.
          </small>
        </p>
        <p>
          <strong>Posição:</strong> Top: {(((startPos.y + window.pageYOffset - 135) / window.innerHeight) * 100).toFixed(2)}%, Left: {(((startPos.x + window.pageXOffset) / window.innerWidth) * 100).toFixed(2)}%
        </p>
        <p>
          <strong>Dimensões:</strong> Width: {width.toFixed(2)}%, Height: {height.toFixed(2)}%
        </p>
      </div>
    </div>
  );
}

export default MouseTracker;