let apihost = "";
let wshost = "";
try {
	let protocol = window.location.protocol == "https:" ? "https:" : "http:";
	let wsprotocol = window.location.protocol == "https:" ? "wss:" : "ws:";
	apihost = window.location.port
		? `${protocol}//${window.location.hostname}:${window.location.port}`
		: `${protocol}//${window.location.hostname}`;
	wshost = window.location.port
		? `${wsprotocol}//${window.location.hostname}:${window.location.port}`
		: `${wsprotocol}//${window.location.hostname}`;
} catch {
	console.log("URL Error");
}

//export const APIHOST = apihost;
export const WSHOST = wshost;

export const APIHOST = apihost; //"http://10.0.5.63/";
//console.log(apihost)


export const POSTLOGIN = `${APIHOST}/api/auth/obtain-token/`;
export const POSTLOGOUT = `${APIHOST}/api/auth/logout/`;
export const GETALLUSERS = `${APIHOST}/api/user/`;
export const GETRANKS = `${APIHOST}/api/user/rank/choices/`;
export const DELETEUSER = `${APIHOST}/api/user/`;
export const CREATEUSER = `${APIHOST}/api/user/?`;
export const EDITUSER = `${APIHOST}/api/user/`;


export const GETLAYOUT = `${APIHOST}/api/factorymanager/layout/HFA/`;
export const POSTLAYOUT = `${APIHOST}/api/factorymanager/layout/`;

export const GETFACTORIES = `${APIHOST}/api/factorymanager/config/factorynames/`;
export const GETFACTORIESGROUPS = `${APIHOST}/api/factorymanager/config/groups/HFA/`;
export const GETMACHINESBYFACTORYHOME = `${APIHOST}/api/factorymanager/config/machinesbyfactory/`;
export const GETMACHINESBYFACTORYINFO = `${APIHOST}/api/factorymanager/config/machines/data/`;

export const GETALL = `${APIHOST}/api/factorymanager/config/`;
export const UPLOADCONFIGFILE = `${APIHOST}/api/factorymanager/config/`;
export const DOWNLOADCONFIFILE = `${APIHOST}/api/factorymanager/config/file/`;


