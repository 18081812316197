const SESSION_KEY = 'loggedInUser';
const AUTHTOKEN_KEY = 'authtoken';

export const saveLoggedInUser = (user, id, rank) => {
  sessionStorage.setItem(SESSION_KEY, JSON.stringify({"name":user, "id":id, "rank":rank}));
};

export const saveAuthToken = (token) => {
  sessionStorage.setItem(AUTHTOKEN_KEY, token);
};

export const getAuthToken = () => {
  return sessionStorage.getItem(AUTHTOKEN_KEY);
};


export const getLoggedInUser = () => {
  const user = sessionStorage.getItem(SESSION_KEY);
  return JSON.parse (user)

};

export const removeLoggedInUser = () => {
  sessionStorage.removeItem(SESSION_KEY);
  sessionStorage.removeItem(AUTHTOKEN_KEY);
};
