import React from "react";
import './download.scss'
import { getAuthToken } from "../../services/auth";
import  axios  from "axios";
import { DOWNLOADCONFIFILE } from '../../contexts/endpoints'
import { GrDownload } from "react-icons/gr";

function DownloadConfigFile() {
    
    const ICON_SIZE = 16; // Define a constant for the size of the icons

    const handleFileDownload = async (event) => {
        event.preventDefault();

        let token = getAuthToken();
      
        const config = {
          method: 'get',
          url: DOWNLOADCONFIFILE,
          responseType: 'blob',
          headers: {
            Authorization: `token ${token}`,
          },
        };
      
        try {
          const response = await axios.request(config);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'configuration.yml');
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error(error);
        }
    }

    return ( 
        <button className="btnDownload" onClick={handleFileDownload}><GrDownload size={ICON_SIZE}/><span className="ml-1">Descarregar ficheiro de configuração atual</span></button>
     );
}

export default DownloadConfigFile;