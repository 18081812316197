/* eslint-disable eqeqeq */
import React from "react";
import "./uploaderConfigFile.scss";
import { useState } from "react";
import axios from "axios";
import { getAuthToken } from "../../services/auth";
import { UPLOADCONFIGFILE } from "../../contexts/endpoints";
import { toast } from "react-toastify";

function UploadConfigFile(props) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let token = getAuthToken();

    const formData = new FormData();
    formData.append("file", selectedFile);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: UPLOADCONFIGFILE,
      headers: {
        Authorization: `token ${token}`,
      },
      data: formData,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
      if (response.status == 200) {
        toast.success(
          `Ficheiro ${selectedFile.name} foi atualizado com sucesso!`
        ); // show success notification
        setSelectedFile(null);
      } else {
        toast.error("Erro ao carregar o documento!"); // show error notification
      }
    } catch (error) {
      console.error(error);
      toast.error(`Erro ao carregar o documento! ${error}`); // show success notification
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div>
      <form className="insertDoc" onSubmit={handleSubmit}>
        <span className="subtitle">
          <strong>Alterar</strong> e <strong>descarregar</strong> o ficheiro de
          configuração
        </span>
        <div className="d-flex">
          <label htmlFor="file" id="file-label">
            Carregar Ficheiro
          </label>
          {selectedFile && (
            <button className="btnUpload" type="submit">
              Upload
            </button>
          )}
        </div>
        {selectedFile && (
          <small className="sm-file">
            Ficheiro selecionado: <strong>{selectedFile.name}</strong>
          </small>
        )}
        <input
          className="btnAddFile"
          type="file"
          id="file"
          onChange={handleFileSelect}
        />
      </form>
    </div>
  );
}

export default UploadConfigFile;
