import "./factoryPage.scss";
import SideBar from "../../components/sideBar/SideBar";
import NavBar from "../../components/navBar/NavBar";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Table from "../../components/table/Table";
import withAuth from "../../services/withAuth";
import { GETALL, GETMACHINESBYFACTORYINFO } from "../../contexts/endpoints";
import { getAuthToken } from "../../services/auth";
import axios from "axios";
import { useSelector } from "react-redux";

function FactoryPage() {
  const [machinesByGroups, setMachinesByGroups] = useState([]);
  const [dataInfoMachine, setDataInfoMachine] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { factory, group } = useParams();

  const selectedFactory = useSelector(
    (state) => state.machines.selectedFactory
  ); 

  const selectedGroup = useSelector((state) => state.machines.selectedGroup);


  useEffect(() => {
    let myHeaders = new Headers();
    let token = getAuthToken();
    myHeaders.append("Authorization", `token ${token}`);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: GETALL,
      headers: {
        Authorization: `token ${token}`,
      },
    };

    const fetchMachinesGroups = async () => {
      try {
        const response = await axios.request(config); // Make the HTTP request using Axios
        let machines = getMachinesByGroup(response.data); // Process the response data to group factories
        //console.log("machines:", machines); // Log the groups to the console
        setMachinesByGroups(machines); // Update the state with the groups of factories
      } catch (error) {
        console.log(error); // Log any errors to the console
      }
    };

    fetchMachinesGroups(); // Call the fetchFactoriesGroups function when the component mounts
  }, []); // The empty array passed as the second argument to useEffect means that this effect will only run once, when the component mounts.

  function getMachinesByGroup(input) {
    const outputMachinesByGroups = {};

    // Ensure that the input has a "factories" property
    if (!input || !input.factories) {
      throw new Error('Invalid input hashmap - missing "factories" property');
    }

    // Loop through each factory in the input hashmap
    for (const factoryName in input.factories) {
      const factory = input.factories[factoryName];

      // Ensure that the factory has a "groups" property
      if (!factory || !factory.groups) {
        outputMachinesByGroups[factoryName] = {};
        continue;
      }

      outputMachinesByGroups[factoryName] = {};

      // Loop through each group in the factory
      for (const groupName in factory.groups) {
        const group = factory.groups[groupName];

        // Ensure that the group is an object
        if (typeof group !== "object") {
          throw new Error(
            `Invalid group "${groupName}" in factory "${factoryName}" - expected an object`
          );
        }

        const postos = {};

        // Loop through each machine in the group
        for (const postosKey in group.postos) {
          const posto = group.postos[postosKey];
          postos[postosKey] = posto;
        }

        // Extract the group details
        const groupDetails = {
          sql: group.sql,
          main_label: group.main_label,
          postolabel: group.postolabel,
          cardlabel: group.cardlabel,
        };

        outputMachinesByGroups[factoryName][groupName] = {
          postos,
          groupDetails,
        };
      }
    }

    return outputMachinesByGroups;
  }

  // this gives me the information I need for each machine
  useEffect(() => {
    const getMachinesInfo = async () => {
      try {
        const token = getAuthToken();
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${GETMACHINESBYFACTORYINFO}${selectedFactory}/${selectedGroup}/`,
          headers: {
            Authorization: `token ${token}`,
          },
        };
        const response = await axios.request(config);
        if (response.status === 200) {
          //console.log("try",response.data);
          setDataInfoMachine(response.data);
        } else {
          throw new Error("Failed to retrieve machine list");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getMachinesInfo(); // Initial call to getMachinesInfo

    const intervalId = setInterval(() => {
      getMachinesInfo();
    }, 6 * 60 * 1000); // 6 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [selectedFactory, selectedGroup]);

  return (
    <>
      <SideBar />
      <NavBar />
      <div className="mainPageFactory">
        <div className="headFactoryGroup">
          <h3 style={{color: 'white'}}>{group}</h3>
        </div>
        <Table
          infomachine={dataInfoMachine}
          data={machinesByGroups[selectedFactory]?.[selectedGroup] || []}
        />
      </div>
    </>
  );
}

export default withAuth(FactoryPage);
