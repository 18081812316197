import { configureStore } from '@reduxjs/toolkit';
import user from './modules/userSlice';
import machines from './modules/machinesSlice';


const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = configureStore({
  reducer: {
    user: user,
    machines: machines,
    //ADD HERE MORE RECUCERS IF I NEED
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('reduxState', JSON.stringify(state));
});

export default store;